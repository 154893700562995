

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

* {
  font-family: "Poppins", sans-serif;
  /* font-weight: 100; */
  font-style: normal;
}


  .custom-check .form-check-input:checked {
      background-color: green;
      border: 1px solid green;
  }

  .custom-check .form-check-input:focus {
      border-color: green;
      box-shadow: 0 0 0 0.25rem rgba(0, 128, 0, 0.25);
  }
  .hover-textt:hover {
    color: #616CD7;
  }

  .main-sidee
{
    border: 1px solid rgba(213, 213, 214, 1) ;
    background-color:rgb(239, 239, 239) ;
    height: 100vh;
}



/* Add this CSS to your stylesheet */
.button-style {
  background-color: #F7F7F7;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.button-style:hover {
  background-color: blue;
  color: white;
}

.table-container {
  padding: 16px;
  overflow-x: auto;
}

/* .responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th, .responsive-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
} */

.responsive-table th {
  background-color: #f2f2f2;
}

.status-badge {
  background-color: yellow;
  padding: 4px 8px;
  border-radius: 4px;
}

.edit-icon {
  margin-left: 8px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .responsive-table thead {
    display: none;
  }
  
  .responsive-table, .responsive-table tbody, .responsive-table tr, .responsive-table td {
    display: block;
    width: 100%;
  }
  
  .responsive-table tr {
    margin-bottom: 15px;
  }
  
  .responsive-table td {
    text-align: right;
    padding-left: 50%;
    /* position: relative; */
  }
  
  .responsive-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}
.calendar-icon {
  cursor: pointer;
  position: relative;
}

.calendar-popup {
  position: absolute;
  /* top: 110%; 
  left: 85%;  */
  transform: translateX(-50%);
  z-index: 10;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-popup {
  position: absolute;
  z-index: 1000;
  margin-top: 10px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.filter-button {
  margin: 5px 0;
  background-color: #EFEFEF;
  border: none;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #ddd;
}

.form-container {
  background-color: #4a90e2;
  padding: 30px;
  border-radius: 10px;
  margin-top: 50px;
}

.custom-form {
  background-color: #6b6bff;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.custom-form .form-label {
  font-weight: bold;
}

.custom-form .form-control {
  margin-bottom: 15px;
}

.custom-form .form-file-label {
  margin-bottom: 15px;
}

.custom-form .btn-success {
  width: 100%;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
}

.custom-form .btn-success:hover {
  background-color: #218838;
}

.required {
  color: red;
}

.profile-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.profile-card {
  background-color: #4a90e2;
  border-radius: 15px;
  color: white;
  padding: 20px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-initial {
  font-size: 50px;
  color: #4a90e2;
}

.info-row {
  margin-top: 10px;
}

.info-row .bi {
  margin-right: 5px;
}

.additional-info {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.document-status {
  margin-top: 20px;
}

.text-danger {
  color: red !important;
}


.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px 10px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  margin-left:8px;
}

.search-icon {
  color: #aaa;
  margin-right: 10px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.search-input::placeholder {
  color: #aaa;
}

.accordion-container {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.accordion-card {
  background-color: #fff;
  color:"black";
  border: none;
}

.accordion-header {
  background-color: #fff;
  color:"black";
  border-bottom: 1px solid #444;
}

.accordion-button {
  color: black;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 0px 5px;
  font-size: 16px;
  height: 35px;
}

.accordion-button:hover {
  text-decoration: none;
}

.accordion-icon {
  transition: transform 0.2s ease;
}

.accordion-icon.rotate {
  transform: rotate(180deg);
}

.accordion-body {
  background-color:#fff;
  color: black;
  padding: 20px;
}
@media (max-width: 768px) {
  .align-items-center {
    justify-content: space-between;
  }
  .text-center {
    text-align: center !important;
  }
  .text-end {
    text-align: end !important;
  }
}

.sliding-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1050;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.sliding-panel-content {
  padding: 20px;
}

.sliding-panel-open {
  transform: translateX(0);
}



/* App.css */
.namo-component {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.toggle-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #6b6bff;
  color: white;
  border-radius: 5px;
}

.popup {
  position: fixed;
  right: -300px; /* Adjust width as needed */
  top: 0;
  width: 300px;
  height: 100%;
  background-color: #6b6bff; /* Adjust color as needed */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup.show {
  right: 0;
  /* width: 100%; */
}

.close-btn {
  align-self: flex-start;
  margin: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  
}

.content {
  padding: 20px;
  color: white;
  text-align: center;
}

hr {
  border: 1px solid #fff;
  margin: 10px 0;
}

/* Responsive Styles */
@media (max-width: 600px) {
  /* .popup {
    width: 100%;
  } */

  .calendar-popup{
    margin-left:-90px;
  }

  .namo-component {
    padding: 20px;
  }

  .toggle-button {
    width: 100%;
    max-width: 300px;
    font-size: 14px;
  }
}

/* FormPopup.css */
.form-popup {
  position: fixed;
  top: 0;
  right: -100%;
  width: 30%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
}

.form-popup.show {
  right: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.form-content {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.save-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}





.task-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
  width: 38 0px; /* Adjust as needed */
  background-color: #fff;
  margin-top: 10px;
}

.task-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
}

.icons-container {
  display: flex;
  gap: 10px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.icon-button i {
  font-size: 16px;
  color: #888; /* Adjust color to match your design */
}

.scroll::-webkit-scrollbar{
  display: none;
}
