.profile-picture {
    width: 100px;
    height: 100px;
    background-color:#FFCC00;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .profile-initial {
    margin-top: 8px;
    font-size: 50px;
    color: #ffffff;
  }

  .profile-back{
    background: linear-gradient(180deg, rgba(124, 159, 251, 0.96) 0%, rgba(85, 86, 200, 0.96) 100%);
    border-radius: 100px !important;
    width: 20vh !important;
    height: 20vh !important;
  }

  .scroll::-webkit-scrollbar{
    display: none;
}