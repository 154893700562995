.otp-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 40px;
    height: 40px;
    margin: 0 4px;
    font-size: 18px;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column;
    }
  
    .logo-container {
      flex: none;
      /* padding: 20px; */
    }
  
    .form-container {
      flex: none;
      /* padding: 20px; */
      width: 100%;
    }
  
    form {
      width: 100%;
    }
  
    input {
      max-width: 100%;
    }
  
    .otp-container {
      justify-content: space-evenly;
    }
  }
  
  @media (max-width: 480px) {
    .logo-container h1 {
      font-size: 36px;
    }
  
    
  
    label {
      font-size: 16px;
    }
  
    input {
      font-size: 16px;
      padding: 6px;
    }
  
    .otp-box {
      width: 35px;
      height: 35px;
      font-size: 16px;
    }
  
    button {
      font-size: 16px;
      padding: 6px 12px;
    }
  }
  
  .otp-class> input{
    border-radius: 6px !important;
    border: none !important;;
  }