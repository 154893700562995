.main-side
{
    border: 1px solid rgba(213, 213, 214, 1) ;
    background-color:rgb(239, 239, 239) ;
    height: 100vh;
}
.menu-btn{
    text-decoration: none;
    border: none;
    /* font-size: 25px; */
    border-radius: 5px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    /* padding: 5px; */
}

.hover-text:hover {
    color: orange;
  }
  
