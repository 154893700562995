.Background-image{
    background-image: url(../assets/Arrow1.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 13px !important;
    padding-bottom: 0.4rem;
    width:35.38px !important;
}

.scroll::-webkit-scrollbar{
    display: none;
}
.scroll div .btn:focus{
    background:#0047E1;
    color: aliceblue !important;
    border: 1px solid #0047E1;
}

/* AssociateData */

.popup-dropdown-container {
  position: relative;
  display: inline-block;
}

.popup-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.popup-content {
  display: block;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 100%;
  max-width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.popup-item {
  padding: 10px;
  cursor: pointer;
}

.popup-item:hover {
  background-color: #f1f1f1;
}

/* Responsive styling */
@media (max-width: 600px) {
  .popup-content {
    width: 100%;
    max-width: 100%;
  }
}

.custom-form {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-form .form-label {
  font-weight: bold;
}

.custom-form .form-control {
  margin-bottom: 15px;
}

.css-wjh20t-MuiPagination-ul{
  flex-wrap: nowrap!important;
}



