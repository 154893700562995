.crd-shdo{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.containerr::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .bg-gr1{
    background: linear-gradient(98.96deg, #548AD8 16.92%, #8A4BD3 85.29%);

  }
  .bg-gr2{
    background: linear-gradient(98.96deg, #F33E62 16.92%, #F79334 85.29%);


  }
  .bg-gr3{
    background: linear-gradient(98.96deg, #893E9C 16.92%, #F82B73 85.29%);

  }
  .bg-gr4{
    background: linear-gradient(98.96deg, #548AD8 16.92%, #8A4BD3 85.29%);


  }

  @media screen  and (min-width:480){
    .for-mobile{
      color: #4e2a37 !important;
    }
    
  }
