.content {
    padding: 20px;
    color: white;
    text-align: center;
  }
  .close-btn {
    align-self: flex-start;
    margin: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    
  }
  .button-style {
    background-color: #F7F7F7;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .button-style:hover {
    background-color: blue;
    color: white;
  }
  @media (max-width: 768px) {
    .align-items-center {
      justify-content: space-between;
    }
    .text-center {
      text-align: center !important;
    }
    .text-end {
      text-align: end !important;
    }
  }

  .filter-popup {
    position: absolute;
    z-index: 1000;
    margin-top: 10px;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .filter-button {
    margin: 5px 0;
    background-color: #EFEFEF;
    border: none;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .filter-button:hover {
    background-color: #ddd;
  }
  
  .form-container {
    background-color: #4a90e2;
    padding: 30px;
    border-radius: 10px;
    margin-top: 50px;
  }

  .custom-form {
    /* background-color: #4a90e2; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: none !important;
  }
  
  .custom-form .form-label {
    font-weight: bold;
  }
  
  .custom-form .form-control {
    margin-bottom: 15px;
  }
  
  .custom-form .form-file-label {
    margin-bottom: 15px;
  }
  
  .custom-form .btn-success {
    width: 100%;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
  }
  
  .custom-form .btn-success:hover {
    background-color: #218838;
  }

  .edit-icon {
    margin-left: 8px;
    cursor: pointer;
  }

  .calendar-icon {
    cursor: pointer;
    position: relative;
  }
  
  .calendar-popup {
    position: absolute;
    /* top: 110%; 
    left: 85%;  */
    transform: translateX(-50%);
    z-index: 10;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .required {
    color: red;
  }
  .dropdown {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 5px;
    z-index: 1000;
    right: 0;
  }
  
  .dropdown-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .dropdown-input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .dropdown-button {
    margin-top: 10px;
    text-align: center;
  }
  